export function prefetchExternal() {
  const externalLinks = document.querySelectorAll('a[href^="http"]');

  externalLinks.forEach((link) => {
    // Create a <link rel="prefetch"> element for each URL
    if (link.href.includes('twitter')) return;
    const prefetchLink = document.createElement('link');
    prefetchLink.rel = 'prefetch';
    prefetchLink.href = link.href;

    // Append the prefetch link to the document head
    document.head.appendChild(prefetchLink);
  });
}
