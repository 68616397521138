// Circle element
export const circleElement = document.querySelector('.circle');

export const hoverElementSectionLinks =
  document.getElementById('section-links');

export const hoverElementSection2 = document.getElementById('section2');
export const hoverElementSection3 = document.getElementById('section3');

export function prohibitedElements() {
  hoverElementSectionLinks.addEventListener('mouseenter', () => {
    circleElement.style.border = '0px solid white';
  });

  hoverElementSectionLinks.addEventListener('mouseleave', () => {
    circleElement.style.border = '1px solid hsl(0, 0%, 60%)';
  });

  hoverElementSection2.addEventListener('mouseenter', () => {
    circleElement.style.border = '0px solid white';
  });

  hoverElementSection2.addEventListener('mouseleave', () => {
    circleElement.style.border = '1px solid hsl(0, 0%, 60%)';
  });

  hoverElementSection3.addEventListener('mouseenter', () => {
    circleElement.style.border = '0px solid white';
  });

  hoverElementSection3.addEventListener('mouseleave', () => {
    circleElement.style.border = '1px solid hsl(0, 0%, 60%)';
  });
}
