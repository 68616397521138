import { circleElement } from './cursor-follow-prohibited-elements';

export class CursorAnimation {
  constructor(circleElement) {
    this.circleElement = circleElement;
    this.mouse = { x: 0, y: 0 }; // Track current mouse position
    this.previousMouse = { x: 0, y: 0 }; // Store the previous mouse position
    this.circle = { x: 0, y: 0 }; // Track the circle position
    this.currentScale = 0; // Track current scale value
    this.currentAngle = 0; // Track current angle value
    this.speed = 0.2; // Smoothing factor for cursor movement speed

    // Bind event listeners
    this.initListeners();
  }

  initListeners() {
    window.addEventListener('mousemove', (e) => this.updateMousePosition(e));
    window.requestAnimationFrame(() => this.tick());
  }

  updateMousePosition(e) {
    this.mouse.x = e.x;
    this.mouse.y = e.y;
  }

  tick() {
    // Calculate circle movement based on mouse position and smoothing
    this.calculateMovement();
    // Apply transformations
    this.applyTransformations();
    // Continue the animation
    window.requestAnimationFrame(() => this.tick());
  }

  calculateMovement() {
    // MOVE
    this.circle.x += (this.mouse.x - this.circle.x) * this.speed;
    this.circle.y += (this.mouse.y - this.circle.y) * this.speed;

    // Update the previous mouse position
    const deltaMouseX = this.mouse.x - this.previousMouse.x;
    const deltaMouseY = this.mouse.y - this.previousMouse.y;
    this.previousMouse.x = this.mouse.x;
    this.previousMouse.y = this.mouse.y;

    // ROTATE
    const mouseVelocity = Math.sqrt(deltaMouseX ** 2 + deltaMouseY ** 2);
    if (mouseVelocity > 20) {
      this.currentAngle = (Math.atan2(deltaMouseY, deltaMouseX) * 180) / Math.PI;
    }
  }

  applyTransformations() {
    const translateTransform = `translate(${this.circle.x}px, ${this.circle.y}px)`;
    // Removed scaleTransform
    const rotateTransform = `rotate(${this.currentAngle}deg)`;

    // Apply only translation and rotation transformations
    this.circleElement.style.transform = `${translateTransform} ${rotateTransform}`;
  }
}

// Initialize the cursor animation
document.addEventListener('DOMContentLoaded', () => {
  // Assuming circleElement is defined/imported from another module
  new CursorAnimation(circleElement);
});
