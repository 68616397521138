export function prefetchImages() {
  const imageUrls = [
    'https://raw.githubusercontent.com/aydinmustafacan/cdn/main/drag-and-drop.webp',
    'https://raw.githubusercontent.com/aydinmustafacan/cdn/main/personal-web-site/blog.aydinmustafa.webp',
  ];

  // Prefetch each image
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
    // Optionally, you can append these images to the DOM as hidden elements
    // to ensure they are cached by the browser.
    img.style.display = 'none';
    document.body.appendChild(img);
  });
}
