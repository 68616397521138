import { loadCursorFollow } from './load-cursor-follow';
import { prefetchInternal } from '../prefetch/prefetch-internal';
import { prefetchExternal } from '../prefetch/prefetch-external';
import { prefetchImages } from '../prefetch/prefetch-images';
import { smoothScrolling } from '../scrolling-section-links-synch/scroll-section-links-synch';

document.addEventListener('DOMContentLoaded', () => {
  loadCursorFollow();
  prefetchInternal();
  prefetchExternal();
  prefetchImages();
  smoothScrolling();
});
