// fixme: this class has many issues, it's not working properly
// also not maintanable, need to be refactored

class SmoothScrolling {
  constructor() {
    this.isScrollingToSection = false;
    this.sections = document.querySelectorAll('section[id]');
    this.links = document.querySelectorAll('aside.sidebar ul li a');
    this.init();
  }

  init() {
    // Attach event listeners
    window.addEventListener('scroll', () => this.onScroll());
    this.links.forEach((link) =>
      link.addEventListener('click', (event) => this.onClickLink(event))
    );

    // Initialize the state
    this.onScroll(); // Set the initial active link
  }

  onScroll() {
    if (this.isScrollingToSection) return;

    this.sections.forEach((section) => {
      let rect = section.getBoundingClientRect();
      if (
        rect.top <= window.innerHeight / 2 &&
        rect.top + rect.height >= window.innerHeight / 2
      ) {
        this.links.forEach((link) => {
          if (section.id === link.getAttribute('href').substring(1)) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        });
      }
    });
  }

  onClickLink(event) {
    event.preventDefault();

    this.isScrollingToSection = true;

    const href = event.currentTarget.getAttribute('href');
    const offsetTop = document.querySelector(href).offsetTop;

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });

    this.resetActiveLinks();
    const currentLink = event.currentTarget;
    currentLink.classList.add('active');

    setTimeout(() => {
      this.resetActiveLinks();
      currentLink.classList.add('active');
      this.isScrollingToSection = false;
    }, 800); // Adjust the timeout duration if needed to match the scroll duration
  }

  resetActiveLinks() {
    this.links.forEach((link) => {
      link.classList.remove('active');
    });
  }
}

export function smoothScrolling() {
  new SmoothScrolling();
}
