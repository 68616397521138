export function prefetchInternal() {
  const hostname = window.location.hostname;
  const internalLinks = document.querySelectorAll(
    `a[href^="/"], a[href^="${window.location.origin}"]`
  );

  internalLinks.forEach((link) => {
    if (!link.href.includes('#')) {
      // Exclude anchor links
      const prefetchLink = document.createElement('link');
      prefetchLink.rel = 'prefetch';
      prefetchLink.href = link.href;
      document.head.appendChild(prefetchLink);
    }
  });
}
